import React, { useState, useEffect } from "react"
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import {
  Link
} from "react-router-dom";
import { motion, useScroll, useSpring } from "framer-motion"
import Button from "../Button";
import Quote from '../Quote'
import './style.scss';
import DigitellectLogo from "./images/digitellect-logo.png";
import DigitellectLogoWhite from "./images/digitellect-logo_wt.png";

const scrollPaths = ['/contact', '/policy', '/case-studies'];
const Header = () => {
  const { scrollYProgress } = useScroll();
  const location = useLocation()
  const { pathname } = location;
  const [activePath, setActivePath] = useState('/');
  const [showQuote, setShowQuote] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  

  const handleScroll = () => {
    if (window.scrollY < 15 && !scrollPaths.includes(window.location.pathname)) {
      setIsScrolled(false);
    } else {
      setIsScrolled(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  }, []);


  useEffect(() => {
    if(scrollPaths.includes(window.location.pathname)) {
      setIsScrolled(true);
          }  
    setActivePath(pathname)
    closeBurgerMenu();
  }, [pathname]);

  const closeBurgerMenu = () => {
    if (document.getElementById('basic-navbar-nav')?.classList.contains("show")) {
      document.getElementById('basic-navbar-nav-toggle')?.click();
    }
  }

  return <> <motion.div style={{ scaleX: useSpring(scrollYProgress), border: '1px solid #fa404e', transformOrigin: '0%', position: 'fixed', zIndex: 9999, width: '100%' }} />
    <header style={{ position: "fixed", top: 0, height: 100, zIndex: 999, width: "100%" }} className={`${isScrolled ? 'scrolled' : ''}`}>

      <Navbar expand="lg">
        <Container fluid>

          <Navbar>

            <Navbar.Brand>
              <Link to="/">
                <img
                  alt=""
                  src={isScrolled ? DigitellectLogo : DigitellectLogoWhite}
                  width="230"

                  className="d-inline-block align-top"
                /></Link>
            </Navbar.Brand>

          </Navbar>
          <Navbar.Toggle aria-controls="basic-navbar-nav" id="basic-navbar-nav-toggle" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Link className={`nav-link ${activePath === '/services' ? 'active' : ''}`} to="/services">SERVICES</Link>
              <Link className={`nav-link ${activePath === '/portfolio' ? 'active' : ''}`} to="/portfolio">PORTFOLIO</Link>
              <Link className={`nav-link ${activePath === '/about' ? 'active' : ''}`} to="/about">ABOUT US</Link>
              <Link className={`nav-link ${activePath === '/contact' ? 'active' : ''}`} to="/contact">CONTACT US</Link>
              <NavDropdown title="MORE" id="basic-nav-dropdown">
              <NavDropdown.Item><Link className="nav-link" to="/design-thinking">DESIGN THINKING</Link></NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item><Link className="nav-link" to="/technology">TECHNOLOGY</Link></NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item><Link className="nav-link" to="/tools">TOOLS</Link></NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item><Link className="nav-link" to="/team">OUR TEAM</Link></NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item><Link className="nav-link" to="/policy">PRIVACY POLICY</Link></NavDropdown.Item>
              {/* <NavDropdown.Divider />
              <NavDropdown.Item><Link className="nav-link" to="/case-studies">CASE STUDIES</Link></NavDropdown.Item> */}
              </NavDropdown>
              <Button className="nav-link get-quote" onClick={() => {
                setShowQuote(true)
                closeBurgerMenu()
              }}>GET A QUOTE</Button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Quote show={showQuote} onHide={() => setShowQuote(false)} />
    </header>
  </>
}
export default Header
import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import { motion } from "framer-motion";
import AnimatedText from "../AnimatedText";
import './style.scss';
import { Helmet } from "react-helmet";

const Team = () => {
    return (<>

        <Helmet>
            <title>Meet the Team - Digitellect's Creative and Technical Experts</title>
            <meta name="description" content="Get to know the talented professionals behind Digitellect. Our team is dedicated to bringing your digital design vision to life." />
            <meta name="keywords" content="creative team, digital agency professionals, design experts, meet the team, Digitellect team, creative talent" />
        </Helmet>
        <Container fluid className="HomeBanner">
            <div className="content">
                <Row className="align-center">
                    <Col sm={12} md={6} lg={{ span: 7 }} className="caption">
                        <h2 className="xl white"><span style={{ fontSize: 20 }} className="grey">Catalysts for digital excellence:</span><br/>
                        <AnimatedText line1="Meet the minds" line2="behind the magic" /></h2>
                    </Col>
                    <Col sm={12} md={6} lg={5}><motion.img initial={{ scale: 0.8 }} whileInView={{
                    scale: 1, transition: {
                    delay: 0.5, duration: 1
                },
                }} style={{ maxWidth: '100%' }} className="aligh-btm" src="images/headimg_Team.png" alt="" /></Col>
                </Row>
            </div>
            <img className="HomeBannerBg" src="images/Pattern_Right-team.png" alt="" />
            {/* <img className="HomeBannerBg top left mob-lt-150" src="images/Pattern_Left-Team.png" alt="" /> */}
        </Container>
        <div className="section-pad">
        <Container fluid>
            <div className="mb-50">
                <motion.h4 initial={{ borderWidth: 0 }}
                whileInView={{
                    borderWidth: 5, transition: {
                        delay: 0.5, duration: 1
                    }
                }}>OUR<br /> TEAM</motion.h4>
                <p className="main-header-text">At Digitellect, our diverse team is the heartbeat of our creativity. From visionary creatives to tech-savvy developers, each member contributes unique skills. We thrive on collaboration, driving projects forward with open communication and collective problem-solving. Together, we deliver innovative designs that exceed expectations.</p>
                {/* <p>"Meet the creative minds behind Digitellect. Our diverse team of designers brings together a wealth of experience and a shared passion for pushing creative boundaries. From graphic/ visual design wizards to UX/UI maestros, and UI developers, each of our team contributes a unique skill set that fuels our commitment to delivering top-notch digital design solutions."</p>
                <p>At "Digitellect", our team is the heartbeat of our creative engine. Comprising diverse talents and perspectives, we unite under a shared passion for pushing the boundaries of digital design. Each member brings a unique skill set, from visionary creatives to tech-savvy developers, collaborating seamlessly to deliver innovative solutions.</p>
                <p>Our team thrives on a culture of collaboration, where open communication and collective problem-solving drive our projects forward. We believe in the power of synergy, where the intersection of expertise leads to designs that not only meet but exceed client expectations.</p> */}
            </div>
            <Row>

                <Col sm={12} md={6} lg={3}>
                    <motion.img initial={{ scale: 0.8 }} whileInView={{
                    scale: 1, transition: {
                    delay: 0.5, duration: 1
                },
                }} src="images/team/Shaikes.jpg" alt="" className="work-items" />
                    <p className="sub-head align-ctr"><b>Shaikes John</b> <br/> Co-Founder, Creative Director/ UX Strategist</p>

                </Col>

                <Col sm={12} md={6} lg={3}>
                    <motion.img initial={{ scale: 0.8 }} whileInView={{
                    scale: 1, transition: {
                    delay: 0.5, duration: 1
                },
                }} src="images/team/Manju.jpg" alt="" className="work-items" />
                    <p className="sub-head align-ctr"><b>Manju Nair</b> <br/> Director- Business Development</p>
                </Col>
                <Col sm={12} md={6} lg={3}>
                    <motion.img initial={{ scale: 0.8 }} whileInView={{
                    scale: 1, transition: {
                    delay: 0.5, duration: 1
                },
                }} src="images/team/Rajesh.jpg" alt="" className="work-items" />
                    <p className="sub-head align-ctr"><b>Rajesh KP</b> <br/> Head of Design/ Creative Director</p>
                </Col>
                <Col sm={12} md={6} lg={3}>
                    <motion.img initial={{ scale: 0.8 }} whileInView={{
                    scale: 1, transition: {
                    delay: 0.5, duration: 1
                },
                }} src="images/team/Romi.jpg" alt="" className="work-items" />
                    <p className="sub-head align-ctr"><b>Romi Dey</b> <br/> Design Director</p>
                </Col>

                <Col sm={12} md={6} lg={3}>
                    <motion.img initial={{ scale: 0.8 }} whileInView={{
                    scale: 1, transition: {
                    delay: 0.5, duration: 1
                },
                }} src="images/team/dmy_image.png" alt="" className="work-items" />
                    <p className="sub-head align-ctr"><b>Sharmila B</b> <br/> Sr. Web Developer</p>
                </Col>
            </Row>

        </Container></div>
    </>

    );
}

export default Team
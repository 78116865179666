import React from "react"
import {
  MemoryRouter as Router,
  Routes,
  Route,
  BrowserRouter
} from "react-router-dom";
import Home from './Home'
import AboutUs from './AboutUs'
import Services from './Services'
import Portfolio from './Portfolio'
import DesignThinking from './DesignThinking'
import Technology from './Technology'
import Tools from './Tools'
import Team from './Team'
import Policy from './Policy'
import CaseStudies from './CaseStudies'
import Layout from './Layout';


function App() {
  return (
    <BrowserRouter>
      <Layout>

        <Routes>
          <Route path="/about" element={<AboutUs />} />
          <Route path="/services/:service?/:subservice?" element={<Services />} />
          <Route path="/portfolio/:service?" element={<Portfolio />} />
          <Route path="/contact" element={<></>} />
          <Route path="/design-thinking" element={<DesignThinking />} />
          <Route path="/technology" element={<Technology />} />
          <Route path="/tools" element={<Tools />} />
          <Route path="/team" element={<Team />} />
          <Route path="/policy" element={<Policy />} />
          <Route path="/case-studies" element={<CaseStudies />} />
          <Route path="/" element={<Home />} />
        </Routes>

      </Layout>
    </BrowserRouter>
  );
}

export default App;


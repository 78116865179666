import React, { useState } from "react"
import { Container, Row, Col } from 'react-bootstrap';
import {
    Link
} from "react-router-dom";
import { ArrowUpShort } from 'react-bootstrap-icons';
import { motion } from "framer-motion";
import AnimatedText from "../AnimatedText";
import Button from "../Button";
import './style.scss';
import { Helmet } from "react-helmet";

const defaultFixedHeight = {
    height: 65,
    overflow: 'hidden'
}
const defaultApproachHeight = {
    height: 105,
    overflow: 'hidden'
}

const AboutUs = ({ isHome = false }: any) => {
    const [moreAboutHome, setMoreAboutHome] = useState(false)
    const [moreVision, setMoreVision] = useState(true)
    const [moreValues, setMoreValues] = useState(true)
    const [moreProcess, setMoreProcess] = useState(true)
    const [moreTeam, setMoreTeam] = useState(false)

    const InnerContent = () => <Container fluid><Row className="more-btn link-text">
        <Col sm={12} md={6} lg={3}><Link to="/about#ourStory" ><Button variant="primary" type="button">
            Our Story: <ArrowUpShort size={20} className="right-align-icon digispliconDown" />
        </Button></Link></Col>
        <Col sm={12} md={6} lg={3}><Link to="/about#ourVision" ><Button variant="primary" type="button">
            Our Vision: <ArrowUpShort size={20} className="right-align-icon digispliconDown" />
        </Button></Link></Col>
        <Col sm={12} md={6} lg={3}><Link to="/about#ourApproach" ><Button variant="primary" type="button">
            Our Approach: <ArrowUpShort size={20} className="right-align-icon digispliconDown" />
        </Button></Link></Col>
        <Col sm={12} md={6} lg={3}>
            <Link to="/about#whyUs" > <Button variant="primary" type="button">Why Choose US: <ArrowUpShort size={20} className="right-align-icon digispliconDown" /></Button></Link>
        </Col>
    </Row></Container>
    return <div className="about-us">

        {isHome ? <Container fluid><div>
            <br className="hideOnMobile" />
            <br className="hideOnMobile" />
            <br className="hideOnMobile" />
            <p className="main-content-text">
                Welcome to Digitellect, where creativity meets technology to transform your brand's vision into stunning digital experiences. <br />
                <span>Our expert team crafts captivating websites, engaging UI/UX designs, and impactful digital marketing campaigns, all tailored to your unique needs. We emphasize robust UX strategies to enhance customer satisfaction and loyalty. Explore Digitellect, where every project is a canvas and every pixel tells a story.
                    Join us in achieving digital excellence.</span>
            </p>
        </div>
            <Row>
                <Col>
                    <motion.div whileHover={{ scale: 1.05 }} className="link-text-home text-end mb-20" onClick={() => setMoreAboutHome(!moreAboutHome)}>
                        {moreAboutHome ? <>LESS ABOUT US <ArrowUpShort size={20} className="left-align-icon digispliconDown" /> </> : <>MORE ABOUT US <ArrowUpShort size={20} className="left-align-icon digisplicon" /></>} 
                    </motion.div>
                </Col>
            </Row>
        </Container> :
            <>

        <Helmet>
        <title>About Digitellect - Your Trusted Digital Design Agency</title>
        <meta name="description" content="Learn more about Digitellect, our mission, values, and expertise in delivering world-class digital design solutions." />
        <meta name="keywords" content="about Digitellect, digital agency background, company values, design expertise, creative agency team" />
        </Helmet>
                <Container fluid className="HomeBanner">
                    <div className="content">
                        <Row className="align-center">
                            <Col sm={12} md={6} lg={{ span: 7 }} className="caption">
                                <h2 className="xl white"><span style={{ fontSize: 20 }} className="grey">Fulfilling visions:</span><br />
                                <AnimatedText line1="Crafting digital dreams" line2="&nbsp;" />
                                
                                </h2>
                            </Col>
                            <Col sm={12} md={6} lg={5}><motion.img initial={{ scale: 0.8 }} whileInView={{
                                scale: 1, transition: {
                                    delay: 0.5, duration: 1
                                },
                            }} src="images/AboutUsImage.png" alt="" /></Col>
                        </Row>
                    </div>
                    <img className="HomeBannerBg mob-rt-40-per" src="images/AboutUsBg.png" alt="" />
                </Container>
                <div className="section-pad">
                    <Container fluid>
                        <motion.h4 initial={{ borderWidth: 0 }}
                            whileInView={{
                                borderWidth: 5, transition: {
                                    delay: 0.5, duration: 1
                                }
                            }} id="aboutUs">ABOUT<br></br> US</motion.h4>
                        <p className="main-header-text">Welcome to Digitellect, where we transform ideas into digital masterpieces. As a leading digital design agency, we specialize in crafting innovative solutions that captivate audiences and elevate brands in the digital landscape.</p>
                    </Container></div>
            </>}

        {(moreAboutHome || !isHome) && (isHome ? <InnerContent /> : <div className="section-pad"><InnerContent /></div>)}
        {
            !isHome && <div className="section-pad"><Container fluid>

                <Row id="ourStory">
                    <Col sm={12} md={12} lg={4}>
                        <div className="our-vision">
                            <p>Our Story:</p>
                            <h5>ELEVATING DIGITAL INNOVATION THROUGH DECADES OF EXPERIENCE</h5>
                            <p style={!moreVision ? defaultFixedHeight : {}}>At Digitellect, our journey began with a vision to revolutionize digital
                                creativity. With more than 28 years in the software and IT industry and over 100 completed projects, our founder leads a talented team driven by innovation. We are dedicated to delivering
                                exceptional digital experiences that set new industry standards.</p>
                            {/* <div className="link-text text-end read-more" onClick={() => setMoreVision(!moreVision)}>
                                READ {moreVision ? <>LESS <ArrowUpShort size={14} className="right-align-icon svg-right-align t6 digisplicon" /></> : <>MORE <ArrowUpShort size={14} className="right-align-icon svg-right-align t6 digispliconDown" /></>}
                            </div> */}
                        </div>
                    </Col>
                    <Col className="pad-m-10" sm={12} md={12} lg={4}><motion.img initial={{ scale: 0.8 }} whileInView={{
                        scale: 1, transition: {
                            delay: 0.5, duration: 1
                        },
                    }} src="images/Our-Vision-1.png" alt="" /></Col>
                    <Col className="pad-m-10" sm={12} md={12} lg={4}><motion.img initial={{ scale: 0.8 }} whileInView={{
                        scale: 1, transition: {
                            delay: 0.5, duration: 1
                        },
                    }} src="images/Our-Vision-2.png" alt="" /></Col>
                </Row>
                <br />

                <Row id="ourVision">
                    <Col className="pad-m-10" sm={12} md={12} lg={4}><motion.img initial={{ scale: 0.8 }} whileInView={{
                        scale: 1, transition: {
                            delay: 0.5, duration: 1
                        },
                    }} src="images/Our-Values.png" alt="" /></Col>
                    <Col className="pad-m-10" sm={12} md={12} lg={4}><motion.img initial={{ scale: 0.8 }} whileInView={{
                        scale: 1, transition: {
                            delay: 0.5, duration: 1
                        },
                    }} src="images/Our-Values2.png" alt="" /></Col>
                    <Col sm={{ span: 12, order: 'first' }} md={{ span: 12, order: 'first' }} xs={{ span: 12, order: 'first' }} lg={4}>
                        <div className="our-values">
                            <p>Our Vision:</p>
                            <h5>PIONEERING TRANSFORMATIVE DIGITAL EXPERIENCES</h5>
                            <p style={!moreValues ? defaultFixedHeight : {}}>Our agency envisions leading the way in crafting transformative digital experiences that seamlessly blend aesthetics with functionality. With innovation and user-centric solutions, we aim to set new industry standards, empowering businesses and individuals alike. Our vision is to inspire, engage, and foster connectivity in the ever-evolving digital landscape.</p>
                            {/* <div className="link-text text-end read-more" onClick={() => setMoreValues(!moreValues)}>
                                READ {moreValues ? <>LESS <ArrowUpShort size={14} className="right-align-icon svg-right-align t6 digisplicon" /></> : <>MORE <ArrowUpShort size={14} className="right-align-icon svg-right-align t6 digispliconDown" /></>}
                            </div> */}
                        </div>
                    </Col>
                </Row>
                <br />

                <Row id="ourApproach">
                    <Col sm={12} md={12} lg={4}>
                        <div className="our-process">
                            <div>
                                <p>Our Approach:</p>
                                <h5>CRAFTING SOLUTIONS, TOGETHER</h5>
                                <div style={!moreProcess ? defaultApproachHeight : {}}>
                                    <p>At Digitellect, we believe in the power of collaboration and creativity. Our team of designers, developers, and strategists work hand in hand to bring your vision to life. By combining cutting-edge technology with artistic flair, we create solutions that leave a lasting impression.</p>
                                </div></div>
                            {/* <div className="link-text text-end read-more" onClick={() => setMoreProcess(!moreProcess)}>
                                READ {moreProcess ? <>LESS <ArrowUpShort size={14} className="right-align-icon svg-right-align svg-right-align t6 digisplicon" /></> : <>MORE <ArrowUpShort size={14} className="right-align-icon svg-right-align t6 digispliconDown" /></>}
                            </div> */}
                        </div>
                    </Col>
                    <Col className="pad-m-10" sm={12} md={12} lg={4}><motion.img initial={{ scale: 0.8 }} whileInView={{
                        scale: 1, transition: {
                            delay: 0.5, duration: 1
                        },
                    }} src="images/Our-Approuch1.jpg" alt="" /></Col>
                    <Col className="pad-m-10" sm={12} md={12} lg={4}><motion.img initial={{ scale: 0.8 }} whileInView={{
                        scale: 1, transition: {
                            delay: 0.5, duration: 1
                        },
                    }} src="images/Our-Approuch2.jpg" alt="" /></Col>
                </Row>
                <br />

                <Row id="whyUs">
                    <Col className="pad-m-10" sm={12} md={12} lg={4}><motion.img initial={{ scale: 0.8 }} whileInView={{
                        scale: 1, transition: {
                            delay: 0.5, duration: 1
                        },
                    }} src="images/Why-Choose-Us.jpg" alt="" /></Col>
                    <Col className="pad-m-10" sm={12} md={12} lg={4}><motion.img initial={{ scale: 0.8 }} whileInView={{
                        scale: 1, transition: {
                            delay: 0.5, duration: 1
                        },
                    }} src="images/Why-Choose-Us2.jpg" alt="" /></Col>
                    <Col sm={{ span: 12, order: 'first' }} md={{ span: 12, order: 'first' }} xs={{ span: 12, order: 'first' }} lg={4}>
                        <div className="our-team">
                            <p>Why Choose Us:</p>
                            <h5>EXCEEDING EXPECTATIONS, EVERY TIME</h5>
                            <div style={!moreTeam ? defaultFixedHeight : {}}>
                                <p>What sets us apart is our unwavering commitment to excellence and client satisfaction.
                                    We prioritize your goals and aspirations, ensuring that every project we undertake exceeds expectations.
                                    With a focus on innovation and quality, we are dedicated to helping you succeed in the digital world.</p>

                                <div className="whychooseSplWrap">
                                    <div className="whychooseSplItem">
                                        <p>MUCH FASTER</p>
                                        <h3>20x</h3>
                                        <p>Faster than hiring creatives in-house</p>
                                    </div>
                                    <div className="whychooseSplItem">
                                        <p>CUT COSTS</p>
                                        <h3>50%</h3>
                                        <p>Reduction in average cost per asset</p>
                                    </div>
                                    <div className="whychooseSplItem">

                                        <p>SHIP FASTER </p>
                                        <h3>70%</h3>
                                        <p>Lower turnaround time for digital assets</p>
                                    </div>
                                    <div className="whychooseSplItem">

                                        <p>STRESS FREE</p>
                                        <h3>9.6</h3>
                                        <p>Average rating from customers</p>
                                    </div>
                                </div>
                            </div>
                            <div className="link-text text-end read-more" onClick={() => setMoreTeam(!moreTeam)}>
                                READ {moreTeam ? <>LESS <ArrowUpShort size={14} className="right-align-icon svg-right-align t6 digisplicon" /></> : <>MORE <ArrowUpShort size={14} className="right-align-icon svg-right-align t6 digispliconDown" /></>}
                            </div>
                        </div>
                    </Col>
                </Row> <br /></Container></div>
        }
    </div>
}

export default AboutUs
import React from "react"
import { motion } from "framer-motion";
import './style.scss';
import { Helmet } from "react-helmet";

const Privacy = () => {

    return <>

        <Helmet>
            <title>Privacy Policy - Digitellect Digital Agency</title>
            <meta name="description" content="Understand how Digitellect collects, uses, and protects your personal information with our privacy policy." />
            <meta name="keywords" content="privacy policy, data protection, information security, user privacy, data collection, Digitellect privacy" />
        </Helmet>

        <p>May 14, 2024</p>
        <p>First things first – your privacy is important to us. That might be the kind of thing all these notices say, but we mean it. You place your trust in us by using Digitellect.com services, and we value that trust. That means we’re committed to protecting and safeguarding your personal data. We act in our clients' best interests and are transparent about the processing of your personal data.</p>
        <p>This document (“this Privacy Statement” or “our Privacy Statement”) describes how we use and process your personal data, provided in a readable and transparent manner. It also tells you what rights you can exercise in relation to your personal data and how you can contact us. Please also read our Cookie Statement, which tells you how Digitellect.com uses cookies and other similar tracking technologies.</p>
        <p>If you’ve used us before, you know that Digitellect.com offers online design-related services through our own website and mobile apps, as well as other online platforms such as partners’ websites and social media. We’d like to point out that all the information you're about to read generally applies to all of these platforms.</p>
        <p>In fact, this single privacy statement applies to any kind of client information we collect through all of the above platforms or by any other means connected to these platforms.</p>
        <p>We might amend this Privacy Statement from time to time, so we recommend visiting this page occasionally to make sure you know where you stand. If we make any updates to this Privacy Statement that will impact you significantly, we’ll inform you about the changes before any new activities begin.</p>

        <motion.h5 initial={{ borderWidth: 0 }}
            whileInView={{
                borderWidth: 5, transition: {
                    delay: 0.5, duration: 1
                }
            }}>Terms we use in this Privacy Statement</motion.h5>
        <p>“Design Service” refers to the various design products and services that can be ordered, acquired, purchased, bought, paid, rented, provided, reserved, combined, or consummated by you from the Design Service Provider.</p>
        <p>“Design Service Provider” refers to the provider of design services (e.g. website design, graphic design, branding), as well as any other design or related product or service as from time to time available for Design Reservation on the platform.</p>
        <p>“Design Reservation” refers to the order, purchase, payment, booking, or reservation of a Design Service.</p>

        <motion.h5 initial={{ borderWidth: 0 }}
            whileInView={{
                borderWidth: 5, transition: {
                    delay: 0.5, duration: 1
                }
            }}>What kind of personal data does Digitellect.com collect?</motion.h5>
        <p>We can’t help you with your design needs without information, so when you use our services, there are certain things we ask for. This is typically routine info – your name, preferred contact details, and the details of the design services you are interested in. You might also decide to submit additional info related to your design requirements.</p>
        <p>In addition to this, we also collect info from the computer, phone, tablet, or other device you use to access our services. This includes the IP address, the browser used, and your language settings. There are also situations when we receive info about you from others or automatically collect other info.</p>

        <motion.h5 initial={{ borderWidth: 0 }}
            whileInView={{
                borderWidth: 5, transition: {
                    delay: 0.5, duration: 1
                }
            }}>Why does Digitellect.com collect and use your personal data?</motion.h5>
        <p>The main reason we ask for personal details is to help you organize your design requirements and ensure you get the best possible service.</p>
        <p>We also use your personal data to contact you about the latest design trends, special offers, and other products or services we think you might be interested in. There are other uses, too. If you’d like to find out what they are, read on for a more detailed explanation.</p>

        <motion.h5 initial={{ borderWidth: 0 }}
            whileInView={{
                borderWidth: 5, transition: {
                    delay: 0.5, duration: 1
                }
            }}>How does Digitellect.com share your data with third parties?</motion.h5>
        <p>There are different parties integrated into Digitellect.com’s services, in various ways and for various reasons. The primary reason we share your data is to provide the Design Service Provider with the relevant info to complete your Design Reservation.</p>
        <p>We also involve other parties to provide you with the Digitellect.com services. This includes, for example, financial institutions, advertisers, subsidiaries of the Digitellect.com corporate group, and the other companies that form the Digitellect Holdings Inc. corporate group. Or, in some cases, if we’re required to by law, we might share your data with governmental or other authorities.</p>
        <p>Below, we go into more detail about how the information you share with us is used and exchanged with these parties.</p><p>
        Digitellect.com, your trusted partner in design solutions, values your privacy and is committed to protecting your personal data. </p>
        <p>Here's how we handle your information:</p>
            <p><strong>Sharing with Clients:</strong>  When you engage Digitellect.com for design services, we may share relevant details with the client for whom the project is being undertaken. This includes information such as your name, contact details, and preferences specified during the project briefing process.</p><p>
            <strong>Historical Information:</strong> In certain cases, we may provide clients with historical data about your past engagements with Digitellect.com, including the number of completed projects, feedback provided, and any relevant preferences you've indicated.</p><p>
            <strong>Connectivity Partners:</strong> Some projects may require us to share your data with our trusted connectivity partners to facilitate the design process effectively.</p><p>
            <strong>Local Offices:</strong> Your data may be shared with Digitellect.com subsidiaries to provide seamless customer service and support.</p><p>
            <strong>Third-party Service Providers:</strong> We engage third-party service providers to assist us in delivering our design solutions. These may include customer support, market research, and payment processing services.</p><p>
            <strong>Marketing and Advertising:</strong> With your consent, we may share limited personal data with advertising partners to ensure that relevant design services and offers reach the right audience.</p><p>
            <strong>Professional Advisors:</strong> In certain circumstances, such as legal disputes, we may need to share your data with professional advisors, including legal firms or auditors.</p><p>
            <strong>Compliance and Legal Obligations:</strong> We may disclose personal data to law enforcement or competent authorities when required by law or to protect our rights or those of our clients.</p><p>
            <strong>Business Partners:</strong> We collaborate with various business partners to extend our design services. When you engage with us through our partners' platforms, relevant personal data may be shared to facilitate and manage your design projects.</p><p>
            <strong>Partner Offers:</strong> If you opt for design services offered through our partners, we may share necessary personal data with them to finalize your project.</p><p>
            <strong>Cross-border Data Transfers:</strong> As a global design agency, your data may be transferred to countries with varying data protection laws. However, we ensure that appropriate safeguards are in place to protect your data.</p><p>
            <strong>Communication and Mobile Devices:</strong> We provide communication tools to facilitate interactions between clients and designers. These communications are monitored for security, fraud prevention, and customer support purposes.</p><p>
            <strong>Social Media Integration:</strong> Digitellect.com may integrate with social media platforms to enhance your experience and facilitate communication. Your social media provider may share information with us in accordance with their privacy policies.</p><p>
            At Digitellect.com, we prioritize the security and confidentiality of your personal data while delivering exceptional design solutions. If you have any concerns or questions regarding your privacy, please don't hesitate to contact us at <a href="mailto:privacy@digitellect.com">privacy@digitellect.com</a>.</p>

        <motion.h5 initial={{ borderWidth: 0 }}
            whileInView={{
                borderWidth: 5, transition: {
                    delay: 0.5, duration: 1
                }
            }}>Digitellect.com Data Security and Privacy Measures</motion.h5>
        <p>At Digitellect.com, safeguarding your personal data is paramount.We implement robust security procedures and retention practices to ensure the utmost protection of your information.</p>

        <motion.h5 initial={{ borderWidth: 0 }
        }
            whileInView={{
                borderWidth: 5, transition: {
                    delay: 0.5, duration: 1
                }
            }}> Data Protection Measures:</motion.h5>
        <p>Access Restrictions: We employ stringent access controls to prevent unauthorized personnel from accessing your personal data.Only authorized individuals with a legitimate need are permitted to handle such information.</p>
        <p>Technical Safeguards: Our servers are fortified with state - of - the - art security measures, including encryption protocols and firewalls, to protect your data from unauthorized access or misuse</p>
        <p>Physical Security: Physical access to our data centers is strictly monitored and restricted to authorized personnel only, ensuring that your data remains secure at all times.</p>

        <motion.h5 initial={{ borderWidth: 0 }}
            whileInView={{
                borderWidth: 5, transition: {
                    delay: 0.5, duration: 1
                }
            }}> Retention Policy:</motion.h5>
        <p>We retain your personal data only for as long as necessary to fulfill the purposes outlined in our services or to comply with legal obligations.This includes maintaining your Digitellect.com user account, resolving disputes, detecting and preventing fraud, and ensuring the smooth operation of our business.</p>

        <motion.h5 initial={{ borderWidth: 0 }}
            whileInView={{
                borderWidth: 5, transition: {
                    delay: 0.5, duration: 1
                }
            }}> Two - Factor Authentication:</motion.h5>
        <p>For enhanced security, we strongly recommend enabling two - factor authentication for your Digitellect.com user account.This additional layer of authentication helps prevent unauthorized access, even if your login credentials are compromised.</p>

        <motion.h5 initial={{ borderWidth: 0 }}
            whileInView={{
                borderWidth: 5, transition: {
                    delay: 0.5, duration: 1
                }
            }}> Protection of Children's Data:</motion.h5>
        <p>Digitellect.com does not knowingly collect personal data from children under 18 years of age without parental consent.Any such data provided in exceptional circumstances, such as travel reservations, is obtained with explicit parental or guardian approval.If we become aware of unauthorized processing of a child's data, we promptly delete it from our systems.</p>

        <motion.h5 initial={{ borderWidth: 0 }}
            whileInView={{
                borderWidth: 5, transition: {
                    delay: 0.5, duration: 1
                }
            }}> Your Control Over Personal Data:</motion.h5>
        <p>We believe in empowering you to control how your personal data is used.You can exercise your rights in the following ways:</p>
        <p><strong>Access and Correction:</strong> You can request a copy of the personal data we hold about you and update any inaccuracies or changes to your information.</p>
        <p><strong>Data Erasure:</strong> In certain situations, you can request the deletion or restriction of the processing of your personal data held by Digitellect.com.</p>
        <p><strong>Withdrawal of Consent:</strong> Where we process your data based on consent, you have the right to withdraw it at any time.</p>
        <p><strong>Third - Party Data Transfer:</strong> You can request the transfer of your personal data to a third party in certain circumstances.</p>

        <motion.h5 initial={{ borderWidth: 0 }}
            whileInView={{
                borderWidth: 5, transition: {
                    delay: 0.5, duration: 1
                }
            }}> Contacting Us:</motion.h5>
        <p>If you have any questions or concerns regarding our privacy practices or the handling of your personal data, please reach out to our Data Protection Officer at <a href="mailto:dataprotection@digitellect.com">dataprotection@digitellect.com</a></p>



        <motion.h5 initial={{ borderWidth: 0 }}
            whileInView={{
                borderWidth: 5, transition: {
                    delay: 0.5, duration: 1
                }
            }}>Responsible Entity:</motion.h5>
        <p>Digitellect.com is operated by Digitellect., a private sole property company headquartered at <strong>No. 2175, Tower - 2, Prestige Shantiniketan, ITPL Main Road, Whitefield, Bangalore - 560048, Karnataka, INDIA.</strong></p>
        <p>For inquiries or assistance related to your reservations, please contact our customer service team through the designated channels.</p>

        <motion.h5 initial={{ borderWidth: 0 }}
            whileInView={{
                borderWidth: 5, transition: {
                    delay: 0.5, duration: 1
                }
            }}> Updates to Privacy Policies:</motion.h5>
        <p>We may periodically update our privacy policies to reflect changes in regulations or our business practices.Any significant updates will be communicated to you directly.We encourage you to review our policies regularly for any changes.</p>

        <motion.h5 initial={{ borderWidth: 0 }}
            whileInView={{
                borderWidth: 5, transition: {
                    delay: 0.5, duration: 1
                }
            }}> Country - Specific Provisions:</motion.h5>
        <p>Depending on your jurisdiction, additional provisions may apply.Please refer to the specific information provided for your country or region.</p>

        <motion.h5 initial={{ borderWidth: 0 }}
            whileInView={{
                borderWidth: 5, transition: {
                    delay: 0.5, duration: 1
                }
            }}> Cookie Statement:</motion.h5>
        <p>When you interact with Digitellect.com online services or apps, we utilize cookies and tracking technologies to enhance your experience and provide personalized content.Learn more about our cookie practices and your choices in our comprehensive Cookie Statement below.</p>

        <motion.h5 initial={{ borderWidth: 0 }}
            whileInView={{
                borderWidth: 5, transition: {
                    delay: 0.5, duration: 1
                }
            }}> Conclusion:</motion.h5>
        <p>At Digitellect.com, protecting your privacy and ensuring the security of your personal data are fundamental aspects of our commitment to you.We continuously strive to uphold the highest standards of data security and privacy protection, empowering you with control over your information while delivering exceptional services.</p>

    </>
}

export default Privacy
import React from 'react';
import { motion } from 'framer-motion';

const Button = ({ children, className, ...props }: any) => <motion.button whileHover={{ scale: 1.05 }} className={`btn btn-primary ${className}`} {...props}>
    {children}
</motion.button>

export default Button;

export const DigiLink = ({ children, className, ...props }: any) => <motion.a whileHover={{ scale: 1.05 }} className={`btn btn-primary ${className}`} {...props}>
    {children}
</motion.a>


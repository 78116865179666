import React from "react";
import { Container } from 'react-bootstrap';
import { motion } from "framer-motion";

const CaseStudies = () => {
    return (
        <Container fluid style={{marginTop:"130px"}}>
            <motion.h4 initial={{ borderWidth: 0 }}
                whileInView={{
                    borderWidth: 5, transition: {
                        delay: 0.5, duration: 1
                    }
                }}>CASE<br /> STUDIES</motion.h4>
            <p>Catalysts for Digital Excellence</p>
            <p>Meet the creative minds behind Digitellect. Our diverse team of designers brings together a wealth of experience and a shared passion for pushing creative boundaries. From graphic/ visual design wizards to UX/UI maestros, and UI developers, each of our team contributes a unique skill set that fuels our commitment to delivering top-notch digital design solutions.</p>
            <p>At Digitellect, our team is the heartbeat of our creative engine. Comprising diverse talents and perspectives, we unite under a shared passion for pushing the boundaries of digital design. Each member brings a unique skill set, from visionary creatives to tech-savvy developers, collaborating seamlessly to deliver innovative solutions.</p>
            <p>Our team thrives on a culture of collaboration, where open communication and collective problem-solving drive our projects forward. We believe in the power of synergy, where the intersection of expertise leads to designs that not only meet but exceed client expectations.</p>
        </Container>
    );
}

export default CaseStudies
import React from 'react';
import { createRoot, hydrateRoot, } from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const domNode = document.getElementById('root') as HTMLElement;

if (domNode.hasChildNodes()) {
  hydrateRoot(domNode, <App />);
} else {
  createRoot(domNode).render(<App />);
}



//  const root = ReactDOM.hydrateRoot(domNode, <App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from "react"
import { Row, Col, Container } from 'react-bootstrap';
import { ArrowUpShort, Instagram, TwitterX, Facebook, Behance, Linkedin } from 'react-bootstrap-icons';
import {
    Link
} from "react-router-dom";
import { motion } from "framer-motion";
import Button, { DigiLink } from "../Button";
import ContactUs from "../ContactUs";
import './style.scss';

const Footer = () => <>
    <Container fluid className="footerbox-pad">
        <ContactUs />
        <Row className="contactus social-btn">
            <Col sm={12} md={6} lg={true}>
                <DigiLink target="_blank" href="https://www.linkedin.com/company/digitellect/">
                <Linkedin size={20} className="left-align-icon" /> LINKEDIN <ArrowUpShort size={20} className="right-align-icon digisplicon" />
            </DigiLink></Col>
            <Col sm={12} md={6} lg={true}>
                <DigiLink target="_blank" href="https://www.instagram.com/digitellect_design/">
                <Instagram size={20} className="left-align-icon" /> INSTAGRAM <ArrowUpShort size={20} className="right-align-icon digisplicon" />
            </DigiLink>
            
            </Col>
            <Col sm={12} md={6} lg={true}>
                <DigiLink target="_blank" href="https://www.facebook.com/profile.php?id=61561493426427">
                <Facebook size={20} className="left-align-icon" /> FACEBOOK <ArrowUpShort size={20} className="right-align-icon digisplicon" />
            </DigiLink>
            
            </Col>
            <Col sm={12} md={6} lg={true}>
                <DigiLink target="_blank" href="https://twitter.com" >
                <TwitterX size={20} className="left-align-icon" /> TWITTER <ArrowUpShort size={20} className="right-align-icon digisplicon" />
            </DigiLink>
            </Col>
            <Col sm={12} md={6} lg={true}><DigiLink target="_blank" href="https://behance.com">
                <Behance size={20} className="left-align-icon" />BEHANCE <ArrowUpShort size={20} className="right-align-icon digisplicon" />
            </DigiLink></Col>
        </Row>
    </Container>
    <footer>
        <Container fluid>
            <Row>
                <Col sm={12} md={12} lg={3}>
                    <div><Link to="/"><motion.img initial={{ scale: 0.8 }} whileInView={{
                        scale: 1, transition: {
                            delay: 0.5, duration: 1
                        },
                    }}
                        alt=""
                        src="./images/digitellect-logo-footer.png"
                        width="130"
                        className="d-inline-block align-top"
                    /></Link></div>
                    <div className="mt-20">
                        <ul>
                            <li>Copyright &copy; 2024 Digitellect</li>
                            <li>All Rights Reserved</li>
                        </ul>
                    </div>
                </Col>
                <Col sm={12} md={12} lg={9}>
                    <Row>
                        <Col sm={12} md={12} lg={2}>
                            <div className="footer-head"><Link className="nav-link" to="/services">SERVICES</Link></div>
                            <div>
                                <ul>
                                    <li><Link reloadDocument className="nav-link" to="/services/Design_Services#serviceDetail">Design</Link></li>
                                    <li><Link reloadDocument className="nav-link" to="/services/Development_Services#serviceDetail">Development</Link></li>
                                    <li><Link reloadDocument className="nav-link" to="/services/Digital_Marketing_Services#serviceDetail">Digital Marketing</Link></li>
                                    <li><Link className="nav-link" to="/services/AI-Powered_Design_Services#serviceDetail">AI Powered Design Services</Link></li>
                                </ul>
                            </div>
                        </Col>

                        <Col sm={12} md={12} lg={2}>
                            <div className="footer-head"><Link className="nav-link" to="/portfolio">PORTFOLIO</Link></div>
                            <div>
                                <ul>
                                    <li><Link reloadDocument className="nav-link" to="/portfolio#latestWorks">Latest Works</Link></li>
                                    <li><Link reloadDocument className="nav-link" to="/portfolio/Design_Services#latestWorks">Design</Link></li>
                                    <li><Link reloadDocument className="nav-link" to="/portfolio/Development_Services#latestWorks">Development</Link></li>
                                    <li><Link reloadDocument className="nav-link" to="/portfolio/Digital_Marketing_Services#latestWorks">Digital Marketing</Link></li>
                                    {/* <li><Link className="nav-link" to="/portfolio/AI-Powered_Design_Services#latestWorks">AI Based</Link></li> */}
                                </ul>
                            </div>
                        </Col>
                        <Col sm={12} md={12} lg={2}>
                            <div className="footer-head"><Link className="nav-link" to="/about">ABOUT US</Link></div>
                            <div>
                                <ul>
                                    <li><Link className="nav-link" to="/about#aboutUs">About Us</Link></li>
                                    <li><Link className="nav-link" to="/about#ourStory">Our Story</Link></li>
                                    <li><Link className="nav-link" to="/about#ourVision">Our Vision</Link></li>
                                    <li><Link className="nav-link" to="/about#ourApproach">Our Approach</Link></li>
                                    <li><Link className="nav-link" to="/about#whyUs">Why Choose Us</Link></li>
                                </ul>
                            </div>
                        </Col>
                        <Col sm={12} md={12} lg={2}>
                            <div className="footer-head">MORE</div>
                            <div>
                                <ul>
                                    <li><Link className="nav-link" to="/design-thinking">Design Thinking</Link></li>
                                    <li><Link className="nav-link" to="/technology">Technology</Link></li>
                                    <li><Link className="nav-link" to="/tools">Tools</Link></li>
                                    <li><Link className="nav-link" to="/team">Our Team</Link></li>
                                    <li><Link className="nav-link" to="/policy">Privacy Policy</Link></li>
                                    {/* <li><Link className="nav-link" to="/case-studies">Case Studies</Link></li> */}
                                </ul>
                            </div>
                        </Col>
                        <Col sm={12} md={12} lg={4}>
                            <div className="footer-head"><Link className="nav-link" to="/contact">CONTACT US</Link></div>
                            <div>
                                <ul className="footaddress">
                                    <li>#2175, Prestige Shantiniketan</li>
                                    <li>ITPL Main Road,Whitefield</li>
                                    <li>Bangalore, Karnataka, IND</li>
                                    <li>Pin - 560048</li>
                                    <br />
                                    <li>Support:<br />
                                        Email: support@digitellect.com<br />
                                        Phone: +91 9980838226
                                    </li>
                                </ul>
                            </div>

                        </Col>
                    </Row>
                </Col>
            </Row>

        </Container>
    </footer>
</>

export default Footer
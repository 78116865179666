import React, { useState, useEffect } from "react"
import { Container, Form, Row, Col, Modal } from 'react-bootstrap';
import { ArrowUpShort } from 'react-bootstrap-icons';
import { useLocation } from 'react-router-dom';
import { useForm } from "react-hook-form"
import { Country } from 'country-state-city';
import Button from "../Button";
import { contact } from "../api";
import './style.scss';
import { Helmet } from "react-helmet";

const ContactUs = () => {
    const location = useLocation()
    const { pathname } = location
    const [isContactPage, setIsContactPage] = useState(false)
    const [countries, setCountries] = useState([])
    const [getInTouch, setGetInTouch] = useState(false)
    const [response, setResponse] = useState({ status: '' })
    const { register, formState: { errors }, handleSubmit, reset, setValue, getValues } = useForm()
    const onSubmit = (data: any) => {
        let payload = { ...data }
        if (data.countryCode && data.number) {
            payload.number = `${data.countryCode} ${data.number}`
        }
        if(data.conOrganization) {
            payload.organization = data.conOrganization
        }
        contact(payload).then((res) => {
            setResponse(res)
            reset()
        }).catch(() => {
            setResponse({ status: 'error' })
        })
    }
    useEffect(() => {
        setGetInTouch(pathname === '/contact')
        setIsContactPage(pathname === '/contact')
    }, [pathname])
    useEffect(() => {
        const _countries: any = Country.getAllCountries()
        setCountries(_countries)
        if (!getValues('countryCode')) {
            setValue('countryCode', '91')
        }
    }, [setValue, getValues])
    return (
    
     <>

        { isContactPage &&  <Helmet>
        <title>Contact Digitellect - Start Your Digital Design Journey Today</title>
        <meta name="description" content="Get in touch with Digitellect to discuss your next digital design project. We're here to help turn your ideas into reality." />
        <meta name="keywords" content="contact Digitellect, digital design consultation, get in touch, project inquiries, design support" />
        </Helmet> }

        <Container fluid>
            <Row>
                <Col sm={12}>
                    <div className={`row getintouchu-panel ${isContactPage ? `getintouch-margin-top`:``}`}>
                        <div className="col-lg-9 col-md-6 col-sm-12">
                            <h2>{isContactPage ? `LET'S CONNECT` : `LET'S DESIGN`}</h2>
                            <p>Embark on a journey where we bring your ideas to life through innovative design. Let's transform your thoughts into captivating visuals. Get in touch, and let the creative collaboration begin!</p>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 pt-80 pad-m-0 align-right">
                            {!isContactPage && <Button className="btn-viewmore" type="button" onClick={() => setGetInTouch(true)}>
                                GET IN TOUCH <ArrowUpShort size={14} className="right-align-icon svg-right-align digisplicon" />
                            </Button>}
                        </div>
                    </div>
                </Col>
            </Row>

            {getInTouch && <Form onSubmit={handleSubmit(onSubmit)}>
                <Row className="form-bg">
                    <Col sm={12}>
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12 mb-20">
                                <Form.Control
                                    type="text"
                                    id="name"
                                    placeholder="Name*"
                                    aria-describedby="Name"
                                    {...register("name", {
                                        required: "Please enter name"
                                    })}
                                    aria-invalid={errors.name ? "true" : "false"}
                                />
                                {errors.name && <p role="alert">{`${errors.name.message}`}</p>}
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 mb-20">
                                <Form.Control
                                    type="email"
                                    id="email"
                                    placeholder="Email*"
                                    aria-describedby="Email"
                                    {...register("email", {
                                        required: "Please enter email address", validate: {
                                            matchPattern: (v) =>
                                                /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                                                "Please enter valid email address",
                                        }
                                    })}
                                    aria-invalid={errors.email ? "true" : "false"}
                                />
                                {errors.email && <p role="alert">{`${errors.email.message}`}</p>}
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 mb-20">
                                <Row>
                                    <Col lg={5} sm={5} md={5}>
                                        <Form.Select id="countryCode"
                                            aria-describedby="Country Code" aria-invalid={errors.countryCode ? "true" : "false"} {...register("countryCode", {
                                                required: "Please select country code"
                                            })}>
                                            {countries.filter((item: any) => item.phonecode).map((item: any, index: any) => <option key={index} value={item.phonecode}>{item.name} ({item.phonecode})</option>)}
                                        </Form.Select>
                                    </Col>
                                    <Col lg={7} sm={7} md={7}>
                                        <Form.Control
                                            type="text"
                                            id="contact"
                                            placeholder="Contact Number*"
                                            aria-describedby="Contact Number"
                                            {...register("number", {
                                                required: "Please enter contact number", validate: {
                                                    matchPattern: (v: any) =>
                                                        /^[+]?[(]?[0-9]{0,9}[)]?[-\s.]?[0-9]{0,9}[-\s.]?[0-9]{1,15}$/.test(v) ||
                                                        "Please enter valid contact number",
                                                }
                                            })}
                                            aria-invalid={errors.number ? "true" : "false"}
                                        />
                                        {/* {!errors.number && <p role="alert">Please enter with country code (eg: +91 9980838226)</p>} */}
                                        {errors.number && <p role="alert">{`${errors.number.message}`}</p>}
                                    </Col>
                                </Row>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 mb-20">
                                <Form.Control
                                    type="text"
                                    id="organization"
                                    placeholder="Organization"
                                    {...register("conOrganization", {
                                        required: "Please enter message"
                                    })}
                                    aria-describedby="Organization"
                                />
                            </div>
                            <div className="col-lg-12 col-md-6 col-sm-12 mb-20">
                                <Form.Control as="textarea" aria-label="Message" placeholder="Message*" rows={3}
                                    {...register("message", {
                                        required: "Please enter message"
                                    })}
                                    aria-invalid={errors.message ? "true" : "false"} />
                                {errors.message && <p role="alert">{`${errors.message.message}`}</p>}
                            </div>
                        </div>
                        <div className="row contact-buttons">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <Button className="btn-viewmore btn-reset" variant="primary" type="reset" onClick={reset}>
                                    Clear Data
                                </Button>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 text-end">
                                <Button className="btn-viewmore btn-submit" type="submit">
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </Col>
                </Row>

            </Form>}
            <Modal show={Boolean(response.status)} onHide={() => setResponse({ status: '' })}>
                <Modal.Header closeButton>
                    <Modal.Title>{response.status === 'success' ? 'Success' : 'Error'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{response.status === 'success' ? <p>We have recieved your information.<br /> Our team will get back to you at the earliest.</p> : <p>We are currently experiencing a technical glitch. We apologize for any inconvenience.<br /> Please try again later</p>}</Modal.Body>
                <Modal.Footer>
                    <Button variant={response.status === 'success' ? 'primary' : 'danger'} onClick={() => setResponse({ status: '' })}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    </>

    )
}

export default ContactUs
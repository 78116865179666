import React, { useState, useEffect, Fragment } from "react";
import { Container, Row, Col, Modal } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router-dom';
import Masonry from 'react-masonry-css';
import { PlusLg, DashLg } from 'react-bootstrap-icons';
import { motion, AnimatePresence } from "framer-motion";
import Button from "../Button";
import AnimatedText from "../AnimatedText";
import Services from '../Services/services.json';
import './style.scss';
import { Helmet } from "react-helmet";

const  Latestportfolio =  [
    {
        "type": "Web Design",
        "image": "images/portfolio/latest/Application16.jpeg"
    },
    {
        "type": "Branding , Packaging",
        "image": "images/portfolio/latest/portfoliodigi002.jpg"
    },
    {
        "type": "Packaging AI Based",
        "image": "images/portfolio/latest/zebrito_whiskey2.png"
    },
    {
        "type": "Brand Identity",
        "image": "images/portfolio/latest/Artistically_Logo.jpg"
    },
    {
        "type": "Brand Identity",
        "image": "images/portfolio/latest/portfoliodigi003.jpg"
    },
    {
        "type": "Dashboard",
        "image": "images/portfolio/latest/Factory-Dashboard-02.jpg"
    }
    
]

const Portfolio = ({ isHome = false }: any) => {
    const location = useLocation()
    const { service } = useParams();
    const { pathname } = location
    const items: any = Services;
    const [activeService, setActiveService] = useState('Latest Works')
    const [onlyBrief, setOnlyBrief] = useState(true)
    const [viewImage, setViewImage] = useState("");



    useEffect(() => {
        setOnlyBrief(!pathname.includes('/portfolio'))
    }, [pathname])
    useEffect(() => {
        if (service) {
            setActiveService(service.split('_').join(' '))
        }
    }, [service])

    const InnerContent = () => <Container fluid>
        <div className="our-portfolio">
            <motion.h4 initial={{ borderWidth: 0 }}
                whileInView={{
                    borderWidth: 5, transition: {
                        delay: 0.5, duration: 1
                    }
                }}>OUR<br></br> PORTFOLIO</motion.h4>
            <p className="main-header-text">In our portfolio, each project is a visual narrative—a testament to the fusion of creativity and functionality. Explore a kaleidoscope of designs that not only captivate but redefine the essence of digital excellence.</p>
        </div>

        <Row>
            <Col sm={12} md={12} lg={4}>
                <Row>
                    <Col sm={12} md={12} lg={11}>
                        <h6 className="mb-20"> SECTORS</h6>
                        {Object.keys(items).map((item, index) => <Fragment key={index}><div id={item.toLowerCase().split(' ').join('-')} onClick={() => {
                            setActiveService(item)
                        }} className={activeService === item ? 'sector-head' : 'other-services'}>{item.toUpperCase()}
                            {activeService === item ? <DashLg size={20} className="icon-align-right" /> : <PlusLg size={20} className="icon-align-right" />}
                        </div>

                            <div className="design-services" style={{ marginBottom: 7 }}>
                                <AnimatePresence initial={false}>
                                    {activeService === item &&
                                        <motion.section
                                            key="content"
                                            initial="open"
                                            animate="open"
                                            exit="collapsed"
                                            variants={{
                                                open: { height: "auto" },
                                                collapsed: { height: 0 }
                                            }}
                                            transition={{ duration: 1, ease: [0.04, 0.62, 0.23, 0.98] }}
                                        ><p>{items[activeService]?.altDescription}</p></motion.section>}</AnimatePresence>
                            </div>

                            {activeService === item && <Col className="mb-10 mobilePortfolioDetail" sm={12} md={12} lg={8}>
                                <h6 id="latestWorks2" className="mb-20 pt-m20">LATEST WORKS</h6>
                                <Masonry
                                    breakpointCols={3}
                                    className="my-masonry-grid"
                                    columnClassName="my-masonry-grid_column">
                                    {(onlyBrief ? items[activeService]?.portfolio?.slice(0, 10) : items[activeService]?.portfolio)?.map((item: any, index: any) => <div className="mashold" key={index}>
                                        <div className="imgholder" ><motion.img initial={{ scale: 0.8 }} whileInView={{
                                            scale: 1, transition: {
                                                delay: 0.5, duration: 1
                                            },
                                        }} src={window.location.origin+`/${item.image}`} alt="" className="work-items" /></div>
                                        <div className="ptfSplwrap">
                                        <div className="porttype">{item.type}</div>
                                            <Button onClick={() => setViewImage(item.image)} className="btn-viewmore" variant="primary" type="button">
                                                View Design
                                            </Button>
                                        </div>
                                    </div>)}
                                </Masonry>
                                {/* <Row>
            
        </Row> */}
                            </Col>}

                        </Fragment>)}
                        <div className="brand-box">Elevating Your Online Presence Through Compelling Visuals
                            <br />
                            <div className="d-grid gap-2 mt-20">
                                <Button variant="primary" type="button">
                                    <a className="call-button" href="tel:+919980838226">LET'S TALK</a>
                                </Button>
                            </div></div>
                    </Col>
                </Row>

            </Col >

            <Col className="mb-10 defaultPortfolioDetail" sm={12} md={12} lg={8}>
                <h6 id="latestWorks" className="mb-20 pt-m20">{activeService.toUpperCase()}</h6>
                <Masonry
                    breakpointCols={2}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column">
                    {(onlyBrief ? items[activeService]?.portfolio.slice(0, 10) : items[activeService]?.portfolio)?.map((item: any, index: any) => <div className="mashold" key={index}>
                        <div className="imgholder"><motion.img initial={{ scale: 0.8 }} whileInView={{
                            scale: 1, transition: {
                                delay: 0.5, duration: 1
                            },
                        }} src={window.location.origin+`/${item.image}`} alt="" className="work-items" /></div>
                        <div className="ptfSplwrap">
                        <div className="porttype">{item.type}</div>
                            <Button onClick={() => setViewImage(item.image)} className="btn-viewmore" variant="primary" type="button">
                                View Design
                            </Button>
                        </div>
                    </div>)}
                        {
                            ! items[activeService]?.portfolio && Latestportfolio.map((item: any, index: any) => <div className="mashold" key={index}>
                            <div className="imgholder"><motion.img initial={{ scale: 0.8 }} whileInView={{
                                scale: 1, transition: {
                                    delay: 0.5, duration: 1
                                },
                            }} src={window.location.origin+`/${item.image}`} alt="" className="work-items" /></div>
                            <div className="ptfSplwrap">
                                <div className="porttype">{item.type}</div>
                                <Button onClick={() => setViewImage(item.image)} className="btn-viewmore" variant="primary" type="button">
                                    View Design
                                </Button>
                            </div>
                        </div>)
                        }

                </Masonry>
                {/* <Row>
            
        </Row> */}
            </Col>

        </Row>
        <Modal size="lg" show={Boolean(viewImage)} onHide={() => setViewImage("")}>
            <Modal.Header closeButton>
                <Modal.Title>{activeService}</Modal.Title>
            </Modal.Header>
            <Modal.Body><motion.img initial={{ scale: 0.8 }} whileInView={{
                scale: 1, transition: {
                    delay: 0.5, duration: 1
                },
            }} alt="" src={window.location.origin+`/${viewImage}`} /></Modal.Body>
            <Modal.Footer>
                <Button variant='primary' onClick={() => setViewImage("")}>
                    OK
                </Button>
            </Modal.Footer>
        </Modal>


    </Container>
    return (<>
        {!isHome && <Container fluid className="HomeBanner">
            <div className="content">
                <Row className="align-center">
                    <Col sm={12} md={6} lg={{ span: 7 }} className="caption">
                        <h2 className="xl white"><span style={{ fontSize: 20 }} className="grey">Explore our creative showcase:</span><br />
                        <AnimatedText line1="Where vision comes" line2="to life" /></h2>
                    </Col>
                    <Col sm={12} md={6} lg={5}><motion.img initial={{ scale: 0.8 }} whileInView={{
                        scale: 1, transition: {
                            delay: 0.5, duration: 1
                        },
                    }} style={{ maxWidth: '100%' }} src="images/PortfolioImage.png" alt="" /></Col>
                </Row>
            </div>
            <img className="HomeBannerBg" src="images/PortfolioBg.png" alt="" />
        </Container>}
        {isHome ? <InnerContent /> : 
        
        <div className="section-pad">

            <Helmet>
        <title>Digitellect Portfolio - Showcasing Exceptional Digital Design Projects</title>
        <meta name="description" content="Browse our portfolio of cutting-edge digital design work. See how we've helped businesses transform their online presence." />
        <meta name="keywords" content="digital design portfolio, case studies, web design examples, creative projects, UX/UI showcase, Digitellect work" />
        </Helmet>
            <InnerContent />
        </div> }
    </>
    );
}

export default Portfolio
import React, { useState } from "react"
import { Container, Modal, Form } from 'react-bootstrap';
import { useForm } from "react-hook-form"
import { Country, State, City } from 'country-state-city';

import Button from "../Button";
import { quote } from "../api";
import Services from '../Services/services.json'

import Who from './Who'
import Where from './Where'
import WhatService from './WhatService'
import WhatKind from './WhatKind'
import When from './When'
import Contact from './Contact'

const titles = [
    "Who is seeking our services?",
    "Please let us know the location of your business.",
    "What specific service do you require?",
    "Which type of ###service### are you interested in? Please choose from the options below.",
    "By when do you need the ###service### ?",
    "How can we contact you?"
]

const items: any = Services
const services = Object.keys(items)
const getKinds = (service: string) => service ? Object.keys(items[service]['services']) : []

const Quote = (props: any) => {
    const [currentStep, setCurrentStep] = useState(1)
    const [response, setResponse] = useState({ status: '' })
    const { register, formState: { errors }, handleSubmit, reset, getValues, watch, setValue } = useForm()

    const onSubmit = (data: any) => {
        if (currentStep === 6) {
            let payload = { ...data }
            if(data.quoteOrganization) {
                payload.organization = data.quoteOrganization
            }
            if (data.who === 'others') {
                payload.who = data.whoOther
            }
            if (data.when === 'others') {
                payload.when = data.whenOther
            }
            if (data.kind.includes('others')) {
                payload.kind = data.kind.join().replace('others', data.kindOther)
            }
            if (data.country) {
                payload.country = Country.getCountryByCode(data.country) ? Country.getCountryByCode(data.country)?.name : data.country
            }
            if (data.country && data.state) {
                payload.state = State.getStateByCodeAndCountry(data.state, data.country) ? State.getStateByCodeAndCountry(data.state, data.country)?.name : data.state
            }
            if (data.countryCode && data.number) {
                payload.number = `${data.countryCode} ${data.number}`
            }
            quote(payload).then((res) => {
                setResponse(res)
                reset()
            }).catch(() => {
                setResponse({ status: 'error' })
            })
        } else {
            setCurrentStep(currentStep + 1)
        }
    }
    const handleClose = () => {
        reset()
        setCurrentStep(1)
        props.onHide()
        setResponse({ status: '' })
    }

    return <Modal
        {...props}
        onHide={handleClose}
        size="lg"
        keyboard={false}
        backdrop={'static'}
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                {response.status ? (response.status === 'success' ? 'Success' : 'Error') : titles[currentStep - 1].replace('###service###', getValues('service'))}
            </Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Body><Container fluid>
                <div className="content">
                    {response.status ?
                        <>
                            {response.status === 'success' ? <p>We have recieved your information.<br /> Our team will get back to you at the earliest.</p> : <p>We are currently experiencing a technical glitch. We apologize for any inconvenience.<br /> Please try again later</p>}
                        </> :
                        <>
                            <Who show={currentStep === 1} register={register} errors={errors} getValues={getValues} />
                            <Where show={currentStep === 2} watch={watch} register={register} errors={errors} Country={Country} State={State} City={City} getValues={getValues} />
                            <WhatService show={currentStep === 3} services={services} register={register} errors={errors} />
                            <WhatKind show={currentStep === 4} kinds={getKinds(getValues('service'))} register={register} errors={errors} getValues={getValues} />
                            <When show={currentStep === 5} register={register} errors={errors} getValues={getValues} />
                            <Contact Country={Country} setValue={setValue} show={currentStep === 6} register={register} errors={errors} getValues={getValues} />
                        </>
                    }
                </div>
            </Container>
            </Modal.Body>
            <Modal.Footer>
                {response.status ?
                    <Button variant={response.status === 'success' ? 'primary' : 'danger'} onClick={handleClose}>
                        OK
                    </Button> :
                    <>
                        {currentStep !== 1 && <Button className="mr-20" variant="primary" type="button" onClick={() => {
                            currentStep === 1 ? handleClose() : setCurrentStep(currentStep - 1)
                        }}>
                            {currentStep === 1 ? 'Close' : 'Back'}
                        </Button>}
                        <Button className="btn-submit" variant="primary" type="submit">
                            {currentStep === 6 ? 'Submit' : 'Next'}
                        </Button>
                    </>
                }
            </Modal.Footer>
        </Form>
    </Modal>
}

export default Quote
